<template>
  <div id="test-group">
     <vs-row vs-w="8" class="flex title">
      <h1>
        {{ lang.botMaker.testGroups.view.testManagement[languageSelected] }}
      </h1>
    </vs-row>
    <EmptyTable
        margin-top="20px"
        v-if="(!testGroups ||testGroups.length === 0) && isAuthAux('konecta.test_groups.add')"
        :text="lang.botMaker.testGroups.table.empty[languageSelected]"
        :buttonText="lang.botMaker.testGroups.view.addTestGroup[languageSelected]"
        :button-action="() => openAddTest(null)"
        
    />
    <TestGroupsList v-if="testGroups &&testGroups.length > 0 && !selectedTestGroup && isAuthAux('konecta.test_groups.list')" :openAddTest="openAddTest" :excecuteTestGroup="excecuteTestGroup" :deleteGroupConfirmation="deleteGroupConfirmation"/>
    <TestGroupDetail v-if="selectedTestGroup && !selectedTestCase" :openAddTest="openAddTest" :openAddTestCase="openAddTestCase" :excecuteTestGroup="excecuteTestGroup" :deleteCaseConfirmation="deleteCaseConfirmation" :listKey="listKey"/>
    <TestCaseDetail v-if="selectedTestCase" :openAddTest="openAddTest" :openAddTestCase="openAddTestCase" :excecuteTestGroup="excecuteTestGroup" />
    <vs-popup
    classContent="popup-example"
    :title="createTestGroup.id ? lang.botMaker.testGroups.view.editTestGroup[languageSelected] : lang.botMaker.testGroups.view.newTestGroup[languageSelected]"
    :active.sync="activePopup"
  >
    <div class="body">
      <new-form-group
          :label="
            lang.botMaker.testGroups.view.name[languageSelected]
          "
        >
      <vs-input
            class="fill"
            required
            id="test-group-name"
            v-model="createTestGroup.name"
            :danger="validations.createTestGroup.nameError"
            :danger-text="
              lang.botMaker.testGroups.view.errors.name[
                languageSelected
              ]
            "
          />
      </new-form-group>
      <new-form-group
          :label="
            lang.botMaker.testGroups.view.description[languageSelected]
          "
        >
        <vs-textarea
          id="test-group-description"
          v-model="createTestGroup.description"
          v-bind:class="{ error: validations.createTestGroup.descriptionError }"
          data-cy="settingDescriptionbot"
        />
        <p class="error-message" v-show="validations.createTestGroup.descriptionError">
          {{
            lang.botMaker.testGroups.view.errors.description[
                languageSelected
              ]
          }}
        </p>
      </new-form-group>
    </div>

    <div class="actions">
      <vs-button v-if="!createTestGroup.id" id="btn-create-test" class="float-right mb-3" @click="createGroup()">
       {{lang.botMaker.testGroups.view.addTestGroup[languageSelected]}}
      </vs-button>
      <vs-button v-if="createTestGroup.id" id="btn-create-test" class="float-right mb-3" @click="updateGroup()">
       {{lang.botMaker.testGroups.view.editTestGroup[languageSelected]}}
      </vs-button>
      <vs-button
        id="btn-cancel"
        type="flat"
        class="float-right mr-4 mb-3"
        color="danger"
        @click="cancel"
      >
        {{ lang.botMaker.cancel[languageSelected] }}
      </vs-button>
    </div>
  </vs-popup>
  <vs-popup
    classContent="popup-example"
    :title="createTestCase.id ? lang.botMaker.testGroups.view.editTestCase[languageSelected] : lang.botMaker.testGroups.view.newTestCase[languageSelected]"
    :active.sync="activeCasePopup"
  >
    <div class="body">
      <new-form-group
          :label="
            lang.botMaker.testGroups.view.name[languageSelected]
          "
        >
      <vs-input
            class="fill"
            required
            id="test-case-name"
            v-model="createTestCase.name"
            :danger="validations.createTestCase.nameError"
            :danger-text="
              lang.botMaker.testGroups.view.errors.name[
                languageSelected
              ]
            "
          />
      </new-form-group>
      <new-form-group
          :label="
            lang.botMaker.testGroups.view.description[languageSelected]
          "
        >
        <vs-textarea
          v-model="createTestCase.description"
          v-bind:class="{ error: validations.createTestCase.descriptionError }"
          id="test-case-description"
        />
        <p class="error-message" v-show="validations.createTestCase.descriptionError">
          {{
            lang.botMaker.testGroups.view.errors.description[
                languageSelected
              ]
          }}
        </p>
      </new-form-group>
    </div>

    <div class="actions">
      <vs-button v-if="!createTestCase.id" id="btn-create-test-group" class="float-right mb-3" @click="createCase()">
       {{lang.botMaker.testGroups.view.addTestCase[languageSelected]}}
      </vs-button>
       <vs-button v-if="createTestCase.id" id="btn-edit-test-group" class="float-right mb-3" @click="updateCase()">
       {{lang.botMaker.testGroups.view.editTestCase[languageSelected]}}
      </vs-button>
      <vs-button
        id="btn-cancel"
        type="flat"
        class="float-right mr-4 mb-3"
        color="danger"
        @click="cancel"
      >
        {{ lang.botMaker.cancel[languageSelected] }}
      </vs-button>
    </div>
  </vs-popup>
  <!-- POPUP EXECUTION -->
  <vs-prompt
      :vs-title="
        lang.botMaker.testGroups.view.executeTestGroup[
          languageSelected
        ]
      "
      :vs-active.sync="isPromptActive"
      :vs-accept-text="lang.botMaker.testGroups.view.execute[languageSelected]"
      :vs-cancel-text="lang.botMaker.cancel[languageSelected]"
      vs-button-cancel="flat"
      @vs-accept="sendExecution"
      @vs-close="cancelExecution"
      @vs-cancel="cancelExecution"
    >
      <vs-row>
        <vs-col vs-w="12">
          <div
            class="intent-examples vs-con-loading__container"
            ref="genButton"
          >
            <vs-input
              id="mail-example"
              class="intent-prompt-input"
              v-on:keyup.enter="addExecutionMail
              
              "
              :label="
                lang.botMaker.testGroups.view.mailsToSendExecution[
                  languageSelected
                ]
              "
              placeholder
              v-model="execution.mail"
              :danger="mailHasError"
              :danger-text="mailErrorMessage"
            />
            <vx-tooltip
              :text="
                lang.botMaker.testGroups.view.addEmail[languageSelected]
              "
            >
              <vs-button
                id="add-mail-btn"
                class="add-intent-btn"
                @click="addExecutionMail"
                icon="add"
              />
            </vx-tooltip>
          </div>
          <div class="intent-examples-list">
            <div class="vs-list">
              <div
                class="vs-list--item"
                v-for="(mail, index) in execution.mails"
                :key="index"
              >
                <div class="list-titles">
                  <span v-if="index !== execution.editingMailIndex">
                    {{ mail }}
                  </span>

                  <div v-else class="flex">
                    <vs-input
                      class="mr-2"
                      v-model="execution.mails[index]"
                      @keyup.enter="saveMail"
                      @keyup.esc="revertMail(index)"
                    >
                      {{ mail }}
                    </vs-input>

                    <vs-button
                      color="primary"
                      type="flat"
                      icon="check"
                      radius
                      @click="saveMail"
                    />
                    <vs-button
                      color="primary"
                      type="flat"
                      icon="clear"
                      radius
                      @click="revertMail(index)"
                    />
                  </div>
                </div>

                <div class="vs-list--slot">
                  <div class="flex">
                    <vs-button
                      color="primary"
                      type="flat"
                      icon="edit"
                      radius
                      @click="editMail(index)"
                    />
                    <vs-button
                      color="primary"
                      type="flat"
                      icon="clear"
                      radius
                      @click="removeMail(mail)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vs-col>
      </vs-row>
    </vs-prompt>
  <!-- END POPUP EXECUTION -->
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import NewFormGroup from '../add-bot/Components/NewFormGroup.vue'
import BotMakerService from '@/services/botMaker.service'
import staffManagementMixin from '@/mixins/staffManagementMixin'

export default {
  name: 'botTest',
  mixins: [staffManagementMixin],
  components: {
    EmptyTable: () => import('../../components/EmptyTable.vue'),
    NewFormGroup,
    TestGroupsList: () => import('./TestGroupsList.vue'),
    TestGroupDetail: () => import('./TestGroupDetail.vue'),
    TestCaseDetail: () => import('./TestCaseDetail.vue')
  },
  props: {
  },
  data() {
    return {
      activePopup: false,
      activeCasePopup: false,
      activeExecutePopup: false,
      createTestGroup: {
        name: '',
        description: '',
        id: null,
      },
      createTestCase: {
				name: '',
				description: '',
        id: null,
			},
      listKey: new Date().getTime(),
      execution: {
        mails: [],
        testGroup: '',
        mail: '',
        previousMail: null,
        editingMailIndex: null,
      },
      validations: {
        emptyMail: false,
        emptyMailList: false,
        mailWithInstance: '',
        duplicateMail: false,
        createTestCase: {
					nameError: false,
					descriptionError: false,
				},
        createTestGroup: {
          nameError: false,
          descriptionError: false,
        }
      },
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'session']),
    ...mapGetters('botMaker', ['testGroups', 'selectedTestGroup', 'selectedTestCase', 'botId', 'bot']),
    mailHasError() {
      return this.validations.emptyMail || this.validations.duplicateMail || this.validations.emptyMailList
    },
    mailErrorMessage() {
      if (this.validations.emptyMail) {
        return this.lang.botMaker.testGroups.view.errors.execution.empty[this.languageSelected]
      }

      if (this.validations.emptyMailList) {
        return this.lang.botMaker.testGroups.view.errors.execution.emptyList[this.languageSelected]
      }

      return this.validations.mailWithInstance
        ? this.lang.botMaker.testGroups.view.errors.execution.duplicate[this.languageSelected]
        : ''
    },
    isPromptActive: {
      get() {
        return this.activeExecutePopup;
      },
      set() {
        return true;
      }
    }
  },
  watch: {
    testGroups() {
    },
  },
  methods: {
    ...mapActions('botMaker', ['UPDATE_TEST_CASE', 'DELETE_TEST_GROUP', 'DELETE_TEST_CASE', 'CREATE_TEST_CASE','CREATE_TEST_GROUP', 'UPDATE_TEST_GROUP', 'GET_TEST_GROUPS', 'GET_BOT']),
    // }
    openAddTest(testGroup) {
      this.validations.createTestGroup.nameError = false;
      this.validations.createTestGroup.descriptionError = false;
      if(testGroup) {
        this.createTestGroup.name = testGroup.name
        this.createTestGroup.description = testGroup.description
        this.createTestGroup.id = testGroup._id
      } else {
        this.createTestGroup.name = ''
        this.createTestGroup.description = ''
        this.createTestGroup.id = null
      }
      this.activePopup = true;
    },
    excecuteTestGroup(testGroup) {
      this.execution.testGroup = testGroup;
      this.activeExecutePopup = true;
    },
    normalizeString(string) {
      if (typeof string !== 'string') return null
      return string
        .trim()
        .toLowerCase()
        .normalize('NFD')
        .replace(/\s\s+/g, ' ')
        .replace(/[\u0300-\u036f]/g, '')
    },
    cancelExecution() {
      this.validations.emptyMail = false;
      this.validations.mailWithInstance = '';
      this.validations.duplicateMail = false;
      this.validations.emptyMailList = false;
      this.execution.mails = [];
      this.execution.testGroup = '';
      this.execution.mail = '';
      this.execution.previousMail = null;
      this.execution.editingMailIndex = null;
      this.activeExecutePopup = false;
    },
    saveMail() {
      this.previousMail = null
      this.execution.editingMailIndex = null
    },
    revertMail(index) {
      this.execution.mails[index] = this.previousMail
      this.execution.editingMailIndex = null
    },
    editMail(index) {
      this.previousMail = this.execution.mails[index]
      this.execution.editingMailIndex = index
    },
    removeMail(m) {
      this.execution.mails = this.execution.mails.filter(mail => {
        return mail !== m
      })
    },
    deleteGroupConfirmation(testGroup) {
      this.$vs.dialog({
            type: 'confirm',
            color: 'warning',
            title: this.lang.botMaker.testGroups.view.messages.groupDeletionTitle[
              this.languageSelected
            ],
            text: this.lang.botMaker.testGroups.view.messages.confirmGroupDeletion[this.languageSelected],
            accept: () => this.deleteGroup(testGroup) 
          })
    },
    async deleteGroup(testGroup) {
      try {
        this.$vs.loading()
        await this.DELETE_TEST_GROUP(testGroup._id);
        this.$vs.loading.close()
          this.$vs.notify({
              text: this.lang.botMaker.testGroups.view.messages.testGroupDeleted[
                this.languageSelected
              ],
              color: 'success'
            })
      } catch (error) {
        console.error(error)
        this.$vs.notify({
          color: 'danger',
          text: this.lang.botMaker.error[this.languageSelected]
        })
        this.$vs.loading.close()
      }
    },
    deleteCaseConfirmation(testCase) {
      this.$vs.dialog({
            type: 'confirm',
            color: 'warning',
            title: this.lang.botMaker.testGroups.view.messages.caseDeletionTitle[
              this.languageSelected
            ],
            text: this.lang.botMaker.testGroups.view.messages.confirmCaseDeletion[this.languageSelected],
            accept: () => this.deleteCase(testCase) 
          })
    },
    async deleteCase(testCase) {
      try {
        this.$vs.loading()
        await this.DELETE_TEST_CASE(testCase._id);
        this.listKey = new Date().getTime();
        this.$vs.loading.close()
          this.$vs.notify({
              text: this.lang.botMaker.testGroups.view.messages.testCaseDeleted[
                this.languageSelected
              ],
              color: 'success'
            })
      } catch (error) {
        console.error(error)
        this.$vs.notify({
          color: 'danger',
          text: this.lang.botMaker.error[this.languageSelected]
        })
        this.$vs.loading.close()
      }
    },
    async sendExecution() {
      if (!this.execution.mails ||this.execution.mails.length === 0) {
        this.validations.emptyMailList = true;
        return
      }
      this.validations.emptyMailList = false;
      try {
        this.$vs.loading()
        await BotMakerService.sendExcecution({ testGroup: this.execution.testGroup, notify: this.execution.mails.join(',') })
        this.activeExecutePopup = false;
        this.$vs.loading.close()
          this.$vs.notify({
              text: this.lang.botMaker.testGroups.view.messages.executionDone[
                this.languageSelected
              ],
              color: 'success'
            })
      } catch (error) {
        console.error(error)
        this.$vs.notify({
          color: 'danger',
          text: this.lang.botMaker.error[this.languageSelected]
        })
        this.$vs.loading.close()
      }
    },
    addExecutionMail() {
      this.validations.emptyMailList = false;
      this.validations.emptyMail =
        this.execution.mail.replace(/\s/g, '').length === 0

      if (this.validations.emptyMail) return

      this.validations.mailWithInstance =
        this.execution.mails
          .map(e => this.normalizeString(e))
          .find(e => e === this.normalizeString(this.execution.mail))

      this.validations.duplicateMail = this.validations.mailWithInstance !== undefined

      if (this.validations.duplicateMail) return

      this.validations.mailWithInstance = '';
      this.execution.mails.push(this.execution.mail)
      this.execution.mail = ''
    },
    openAddTestCase(testCase) {
      this.validations.createTestCase.nameError = false;
      this.validations.createTestCase.descriptionError = false;
      if(testCase) {
        this.createTestCase.name = testCase.name
        this.createTestCase.description = testCase.description
        this.createTestCase.id = testCase._id
      } else {
        this.createTestCase.name = ''
        this.createTestCase.description = ''
        this.createTestCase.id = null
      }
      this.activeCasePopup = true;
    },
    async createCase() {
      if (!this.createTestCase.name) {
        this.validations.createTestCase.nameError = true;
      } else {
        this.validations.createTestCase.nameError = false;
      }
      if (!this.createTestCase.description) {
        this.validations.createTestCase.descriptionError = true;
      } else {
        this.validations.createTestCase.descriptionError = false;
      }
      if (this.validations.createTestCase.descriptionError || this.validations.createTestCase.nameError) {
        return;
      }
      this.$vs.loading()
      let name = this.createTestCase.name;
      let description = this.createTestCase.description;
      try {
        await this.CREATE_TEST_CASE({name, description, testGroup: this.selectedTestGroup._id })
        this.activeCasePopup = false;
        this.createTestCase.name = '';
        this.createTestCase.description = '';
        this.listKey = new Date().getTime();
         this.$vs.loading.close()
         this.$vs.notify({
            text: this.lang.botMaker.testGroups.view.messages.testCaseCreated[
              this.languageSelected
            ],
            color: 'success'
          })
      } catch (error) {
        console.error(error)
        this.$vs.notify({
          color: 'danger',
          text: this.lang.botMaker.error[this.languageSelected]
        })
        this.$vs.loading.close()
      }
    },
    async updateCase() {
      if (!this.createTestCase.name) {
        this.validations.createTestCase.nameError = true;
      } else {
        this.validations.createTestCase.nameError = false;
      }
      if (!this.createTestCase.description) {
        this.validations.createTestCase.descriptionError = true;
      } else {
        this.validations.createTestCase.descriptionError = false;
      }
      if (this.validations.createTestCase.descriptionError || this.validations.createTestCase.nameError) {
        return;
      }
      this.$vs.loading()
      let name = this.createTestCase.name;
      let description = this.createTestCase.description;
      let id = this.createTestCase.id;
      try {
        await this.UPDATE_TEST_CASE({name, description, id })
        this.activeCasePopup = false;
        this.createTestCase.name = '';
        this.createTestCase.description = '';
        this.createTestCase.id = null;
        this.listKey = new Date().getTime();
         this.$vs.loading.close()
         this.$vs.notify({
            text: this.lang.botMaker.testGroups.view.messages.testCaseEdited[
              this.languageSelected
            ],
            color: 'success'
          })
      } catch (error) {
        console.error(error)
        this.$vs.notify({
          color: 'danger',
          text: this.lang.botMaker.error[this.languageSelected]
        })
        this.$vs.loading.close()
      }
    },
    cancel() {
      this.activeCasePopup = false;
      this.activePopup = false;
    },
    async createGroup() {
      if (!this.createTestGroup.name) {
        this.validations.createTestGroup.nameError = true;
      } else {
        this.validations.createTestGroup.nameError = false;
      }
      if (!this.createTestGroup.description) {
        this.validations.createTestGroup.descriptionError = true;
      } else {
        this.validations.createTestGroup.descriptionError = false;
      }
      if (this.validations.createTestGroup.descriptionError || this.validations.createTestGroup.nameError) {
        return;
      }
      this.$vs.loading()
      let name = this.createTestGroup.name;
      let description = this.createTestGroup.description;
      try {
        await this.CREATE_TEST_GROUP({name, description, service: this.bot.service.id })
        this.activePopup = false;
        this.createTestGroup.name = '';
        this.createTestGroup.description = '';
        this.createTestGroup.id = null;
         this.$vs.loading.close()
         this.$vs.notify({
            text: this.lang.botMaker.testGroups.view.messages.testGroupCreated[
              this.languageSelected
            ],
            color: 'success'
          })
      } catch (error) {
        console.error(error)
        this.$vs.notify({
          color: 'danger',
          text: this.lang.botMaker.error[this.languageSelected]
        })
        this.$vs.loading.close()
      }
    },
    async updateGroup() {
      if (!this.createTestGroup.name) {
        this.validations.createTestGroup.nameError = true;
      } else {
        this.validations.createTestGroup.nameError = false;
      }
      if (!this.createTestGroup.description) {
        this.validations.createTestGroup.descriptionError = true;
      } else {
        this.validations.createTestGroup.descriptionError = false;
      }
      if (this.validations.createTestGroup.descriptionError || this.validations.createTestGroup.nameError) {
        return;
      }
      this.$vs.loading()
      let name = this.createTestGroup.name;
      let description = this.createTestGroup.description;
      let id = this.createTestGroup.id;
      try {
        await this.UPDATE_TEST_GROUP({name, description, id });
        this.activePopup = false;
        this.createTestGroup.name = '';
        this.createTestGroup.description = '';
        this.createTestGroup.id = null;
         this.$vs.loading.close()
         this.$vs.notify({
            text: this.lang.botMaker.testGroups.view.messages.testGroupEdited[
              this.languageSelected
            ],
            color: 'success'
          })
      } catch (error) {
        console.error(error)
        this.$vs.notify({
          color: 'danger',
          text: this.lang.botMaker.error[this.languageSelected]
        })
        this.$vs.loading.close()
      }
    },
  },
  async mounted() {
    const BOT_ID = this.$route.query.botId
    if (!this.bot || this.bot.id !== BOT_ID) {
      await this.GET_BOT(BOT_ID);
    }
    this.GET_TEST_GROUPS(this.bot.service.id);
  }
}
</script>

<style lang="scss">
#test-group {
  .title {
    margin-bottom: 20px;
  }
}
.intent-prompt-input {
    width: 100%;
    margin-bottom: 20px;
  }
  .intent-examples {
    display: flex;
    align-items: center;
  }
  .intent-input {
    width: 50%;
    display: flex;
    align-items: center;
  }
  .add-intent-btn {
    margin-left: 5px;
  }
  .intent-example {
    display: flex;
    align-items: center;
  }
  .clear-intent-btn {
    margin-right: 5px;
  }
  .intent-examples-list {
    max-height: 400px;
    overflow-y: auto;
  }
  .con-vs-dialog .vs-dialog footer button:not(:first-of-type) {
    background: rgba(var(--vs-danger), 1) !important;
  }
  .con-vs-dialog .vs-dialog footer {
    display: flex;
    flex-direction: row-reverse !important;
    justify-content: flex-end;
  }
  .con-vs-dialog .vs-dialog footer .vs-button {
    margin-left: 0.5rem;
  }
  .con-vs-dialog .vs-dialog {
    max-width: 650px;
  }
  .span-text-validation-danger {
    height: unset !important;
  }
.error-message {
  display: block;
  height: 19px;
  color: rgba(var(--vs-danger), 1);
  font-size: 0.65rem;
}
</style>
